import React, { useState } from 'react';
import {
  Button,
  Label,
  FormGroup,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Alert,
  ModalFooter,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
} from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import AuthLogo from '../../layouts/logo/AuthLogo';
import { ReactComponent as LeftBg } from '../../assets/images/bg/login-bgleft.svg';
import { ReactComponent as RightBg } from '../../assets/images/bg/login-bg-right.svg';
import useAxios from '../../hooks/useAxios';

const Register = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const [isSuccess, setIsSuccess] = useState(false);
  const [visible, setVisible] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [modal, setModal] = useState(false);
  const [dataSubmit, setDataSubmit] = useState();

  const onDismiss = () => {
    setVisible(false);
  };

  const toggle = () => {
    setModal(!modal);
  };

  const api = useAxios();

  const initialValues = {
    nama_perusahaan: '',
    bentuk_usaha: '',
    tipe: '',
    pilihan_pengadaan: '',
    email: '',
    password: '',
    confirmPassword: '',
    acceptTerms: false,
  };

  const validationSchema = Yup.object().shape({
    nama_perusahaan: Yup.string().required('Nama Perusahaan harus diisi'),
    bentuk_usaha: Yup.string().required('Bentuk Perusahaan harus diisi'),
    tipe: Yup.string().required('Tipe penyedia harus diisi'),
    pilihan_pengadaan: Yup.string().required('Pilihan pengadaan harus diisi'),
    email: Yup.string().email('Email is invalid').required('Email harus diisi'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password harus diisi'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password harus diisi'),
  });

  const confirm = async (data) => {
    setModal(true);
    setDataSubmit(data);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    await api
      .post('api/auth2/register', dataSubmit)
      .then(() => {
        setIsSuccess(true);
      })
      .catch((err) => {
        setErrMsg(err.response.data.message);
      });
    document.getElementById('register-form').reset();
    setIsConfirmed(false);
    setModal(false);
    setIsLoading(false);
  };

  return (
    <div className="" style={{ position: 'relative', minHeight: '100vh' }}>
      <LeftBg className="position-absolute left bottom-0" />
      <RightBg className="position-absolute end-0 top" />
      <Container fluid className="h-100">
        <Row className="justify-content-center align-items-center h-100 ">
          <Col xs="12" sm="10" lg="7">
            <Link to="/" style={{ textDecoration: 'none' }}>
              <AuthLogo />
            </Link>
            <Card className="rounded-4">
              <CardBody className="p-4 m-1">
                {errMsg && (
                  <Alert
                    className="rounded-4"
                    color="danger"
                    isOpen={visible}
                    toggle={onDismiss.bind(null)}
                  >
                    {errMsg}
                  </Alert>
                )}
                {isSuccess && (
                  <Alert className="rounded-4" color="success">
                    Registrasi berhasil. Silakan cek email Anda untuk konfirmasi.
                  </Alert>
                )}
                <h4 className="mb-0 fw-bold">Registrasi</h4>
                <small className="pb-4 d-block">
                  Apakah Anda sudah punya akun? Silakan <Link to="/auth/login">Login</Link>
                </small>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(fields) => confirm(fields)}
                >
                  {({ errors, touched }) => (
                    <Form id="register-form">
                      <Row>
                        <Col sm="12" md="4">
                          <FormGroup>
                            <Label htmlFor="bentuk_usaha">Bentuk Perusahaan</Label>
                            <Field
                              id="bentuk_usaha"
                              name="bentuk_usaha"
                              as="select"
                              className={`form-control ${
                                errors.bentuk_usaha && touched.bentuk_usaha ? 'is-invalid' : ''
                              }`}
                            >
                              <option value="" disabled>
                                - Pilih -
                              </option>
                              <option value="PT">PT</option>
                              <option value="CV">CV</option>
                              <option value="Firma">Firma</option>
                              <option value="Koperasi">Koperasi</option>
                              <option value="Lainnya">Lainnya</option>
                            </Field>
                            <ErrorMessage
                              name="bentuk_usaha"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12" md="8">
                          <FormGroup>
                            <Label htmlFor="nama_perusahaan">Nama Perusahaan</Label>
                            <Field
                              id="nama_perusahaan"
                              name="nama_perusahaan"
                              type="text"
                              className={`form-control ${
                                errors.nama_perusahaan && touched.nama_perusahaan
                                  ? ' is-invalid'
                                  : ''
                              }`}
                            />

                            <ErrorMessage
                              name="nama_perusahaan"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup>
                        <Label htmlFor="tipe">Tipe Penyedia</Label>
                        <Field
                          id="tipe"
                          name="tipe"
                          as="select"
                          className={`form-control ${
                            errors.tipe && touched.tipe ? 'is-invalid' : ''
                          }`}
                        >
                          <option value="" disabled>
                            - Pilih -
                          </option>
                          <option value="Barang">Barang</option>
                          <option value="Jasa">Jasa</option>
                          <option value="Barang dan Jasa">Barang & Jasa</option>
                        </Field>
                        <ErrorMessage name="tipe" component="div" className="invalid-feedback" />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="pilihan_pengadaan">Pengadaan Yang Ingin Diikuti</Label>
                        <Field
                          id="pilihan_pengadaan"
                          name="pilihan_pengadaan"
                          as="select"
                          className={`form-control ${
                            errors.pilihan_pengadaan && touched.pilihan_pengadaan
                              ? 'is-invalid'
                              : ''
                          }`}
                        >
                          <option value="" disabled>
                            - Pilih -
                          </option>
                          <option value="umum">Pengadaan Internal Kantor PT PEMA</option>
                          <option value="scm">Pengadaan Bisnis/Projek</option>
                        </Field>
                        <ErrorMessage
                          name="pilihan_pengadaan"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="email">Email</Label>
                        <Field
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="on"
                          className={`form-control${
                            errors.email && touched.email ? ' is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="password">Password</Label>
                        <Field
                          id="password"
                          name="password"
                          type="password"
                          className={`form-control${
                            errors.password && touched.password ? ' is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="confirmPassword">Confirm Password</Label>
                        <Field
                          id="confirmPassword"
                          name="confirmPassword"
                          type="password"
                          className={`form-control${
                            errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          name="confirmPassword"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                      {/* <FormGroup inline className="form-check">
                        <Field
                          type="checkbox"
                          name="acceptTerms"
                          id="acceptTerms"
                          className={`form-check-input ${
                            errors.acceptTerms && touched.acceptTerms ? ' is-invalid' : ''
                          }`}
                          onChange={(e) => setIsAcceptTerms(e.target.checked)}
                        />
                        <Label htmlFor="acceptTerms" className="form-check-label">
                          Accept Terms & Conditions
                        </Label>
                        <ErrorMessage
                          name="acceptTerms"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup> */}
                      <FormGroup>
                        <Button type="submit" color="primary" className="me-2">
                          Submit
                        </Button>
                        <Button type="reset" color="secondary" outline>
                          Reset
                        </Button>
                      </FormGroup>
                      <Modal isOpen={modal} toggle={toggle.bind(null)} centered>
                        <ModalHeader>Konfirmasi</ModalHeader>
                        <ModalBody>
                          <div className="d-flex flex-row gap-2">
                            <Input
                              id="confirm"
                              type="checkbox"
                              checked={isConfirmed}
                              onChange={(e) => setIsConfirmed(e.target.checked)}
                            />
                            <Label htmlFor="confirm">
                              Silakan cek kembali data Perusahaan. Centang jika data sudah benar.
                            </Label>
                          </div>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            type="submit"
                            color="primary"
                            size="sm"
                            disabled={!isConfirmed || isLoading}
                            className="me-2"
                            onClick={handleSubmit}
                          >
                            {isLoading ? (
                              <div className="d-flex align-items-center gap-2">
                                <Spinner size="sm" />
                                Mengirim..
                              </div>
                            ) : (
                              'Lanjutkan'
                            )}
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Register;
