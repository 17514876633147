import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import Themeroutes from './routes/Router';
import ThemeSelector from './layouts/theme/ThemeSelector';
import Loader from './layouts/loader/Loader';

const App = () => {
  const routing = useRoutes(Themeroutes);
  // const direction = useSelector((state) => state.customizer.isRTL);
  // const isMode = useSelector((state) => state.customizer.isDark);

  return (
    <Suspense fallback={<Loader />}>
      <ThemeSelector />
      {routing}
    </Suspense>
  );
};

export default App;
